import React from "react";
import ScrollableAnchor from "react-scrollable-anchor";
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import {IntlProvider, FormattedMessage} from 'react-intl';

export default () => (
  <ScrollableAnchor id={"pricing"}>
    <section className="bg-gray-100 py-8">
      <div className="container mx-auto px-2 pt-4 pb-1 text-gray-800">
        <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800">
        <FormattedMessage id="Pricing" />
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4">
          <PricingItemSides
            name="Basic"
            featuresList={[
              "Emails with custom service status for each customer",
              "Email Campaigns with click tracking",
              "450 emails per month (non-cumulative)",
              "Distribution of arrival jobs by weekday"
            ]}
            price="€20"
            periodicity="/per month"
          />

          <PricingItemMiddle
            name="Intermediate"
            featuresList={[
              "Emails with custom service status for each customer",
              "Email Campaigns with click tracking",
              "1200 emails per month (non-cumulative)",
              "50 SMS per month (non-cumulative)",
              "Monthly Earnings Metrics",
              "Distribution of arrival jobs by weekday"
            ]}
            price="€35"
            periodicity="/per month"
          />

          <PricingItemSides
            name="Pro"
            featuresList={[
              "Emails with custom service status for each customer",
              "Create and send highlights or campaigns by email",
              "SMS with personalized service status for each customer"
            ]}
            price="Custom"
            periodicity="/per month"
          />
        </div>
      </div>
      <div className="container mx-auto text-center">
        <p className="text-xl text-gray-800 font-bold">
        <FormattedMessage id="Not sure which plan is right for you?" />
        </p>
        <p className="text-lg font-bold text-gray-700 underline">
          <a><FormattedMessage id="Sign up and have a free trial for 30 days" /></a>
        </p>
      </div>
    </section>
  </ScrollableAnchor>
);

const PricingItemSides = props => (
  <div className="flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-white mt-4">
    <div className="flex-1 bg-white text-gray-600 rounded-t rounded-b-none overflow-hidden shadow">
      <div className="p-8 text-3xl font-bold text-center border-b-4">
        <FormattedMessage id={props.name} />
      </div>
      <ul className="w-full text-center text-sm">
        {props.featuresList.map(feature => (
          <li className="border-b py-4"><FormattedMessage id={feature} /></li>
        ))}
      </ul>
    </div>
    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
      <div className="w-full pt-6 text-3xl text-gray-600 font-bold text-center">
        {props.price} <span className="text-base"><FormattedMessage id={props.periodicity} /></span>
      </div>
      <div className="flex items-center justify-center">
        <OutboundLink
          href="http://app.closer-office.com/auth/register"
          target="__blank"
          className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg"
        >
          <FormattedMessage id="Sign Up" />
        </OutboundLink>
      </div>
    </div>
  </div>
);

const PricingItemMiddle = props => (
  <div className="flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-white mt-4 sm:-mt-6 shadow-lg z-10">
    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <div className="w-full p-8 text-3xl font-bold text-center">
        <FormattedMessage id={props.name} />
      </div>
      <div className="h-1 w-full gradient my-0 py-0 rounded-t"></div>
      <ul className="w-full text-center text-base font-bold">
        {props.featuresList.map(feature => (
          <li className="border-b py-4"><FormattedMessage id={feature} /></li>
        ))}
      </ul>
    </div>
    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
      <div className="w-full pt-6 text-4xl font-bold text-center">
        {props.price} <span className="text-base"><FormattedMessage id={props.periodicity} /></span>
      </div>
      <div className="flex items-center justify-center">
        <OutboundLink
          href="http://app.closer-office.com/auth/register"
          target="__blank"
          className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg"
        >
          <FormattedMessage id="Sign Up" />
        </OutboundLink>
      </div>
    </div>
  </div>
);
