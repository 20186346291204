import React from "react"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import {IntlProvider, FormattedMessage} from 'react-intl';

export default () => (
    <section className="container mx-auto text-center py-6 mb-12">

        <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white"><FormattedMessage id="Want to be our reseller?" /></h2>
        <div className="w-full mb-4">
            <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <h3 className="my-4 text-3xl leading-tight"><FormattedMessage id="We distribute our earnings!" /></h3>

        <OutboundLink href="https://www.closerdev.com">
            <button type="button"
                className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg"><FormattedMessage id="Contact us!" /></button>
        </OutboundLink>

    </section>
)