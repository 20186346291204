import React from "react";
import firstImg from "../images/undraw_add_user_ipe3.svg";
import secondImg from "../images/undraw_add_file2_gvbb.svg";
import thirdImg from "../images/undraw_status_update_jjgk.svg";
import fourthImg from "../images/undraw_done_a34v.svg";
import ScrollableAnchor from "react-scrollable-anchor";

import {IntlProvider, FormattedMessage} from 'react-intl';



export default () => (
  
  <ScrollableAnchor id={"process"}>
    <section className="bg-white border-b py-8">
      <div className="container max-w-5xl mx-auto m-8">
        <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800">
          <FormattedMessage id="How it works" />
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div className="flex flex-wrap">
          <ul className="timeline">
            <TimelineItemLeft
              img={firstImg}
              title="Register client in platform"
              text="register description"
            />
            <TimelineItemRight
              img={secondImg}
              title="Add a job for a client"
              text="add description"
            />
            <TimelineItemLeft
              img={thirdImg}
              title="Real time updates to your clients"
              text="updates description"
            />
            <TimelineItemRight
              img={fourthImg}
              title="Let your clients know when the job is done"
              text="done description"
            />
          </ul>
        </div>
      </div>
    </section>
  </ScrollableAnchor>

);

const TimelineItemLeft = props => (
  <li>
    <div className="direction-l repos">
      <div className="widthImg py-6 text-center">
        <img className="imgFile" src={props.img} />
      </div>
    </div>
    <div className="direction-r">
      <div className="flag-wrapper">
        <span className="flag"><FormattedMessage id={props.title} /></span>
      </div>
      <div className="desc text-justify"><FormattedMessage id={props.text} /></div>
    </div>
  </li>
);

const TimelineItemRight = props => (
  <li>

    <div className="direction-r repos">
      <div className="widthImg py-6 text-center">
        <img className="imgFile" src={props.img} />
      </div>
    </div>
    <div className="direction-l">
      <div className="flag-wrapper">
        <span className="flag"><FormattedMessage id={props.title} /></span>
      </div>
      <div className="desc text-justify"><FormattedMessage id={props.text} /></div>
    </div>
  </li>
);
