import React from "react";
import improve from "../images/undraw_invest_88iw.svg";
import marketing from "../images/undraw_message_sent_1030.svg";
import connect from "../images/undraw_progress_tracking_7hvk.svg";
import ScrollableAnchor from "react-scrollable-anchor";
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import {IntlProvider, FormattedMessage} from 'react-intl';


export default () => (
  <ScrollableAnchor id={"features"}>
    <section className="bg-white border-b py-8">
      <div className="container mx-auto flex flex-wrap pt-4 pb-12">
        <h3 className="w-full my-2 text-4xl font-bold leading-tight text-center text-gray-800">
          <FormattedMessage id="Start Growing With Closer Office Now" />
        </h3>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <FeatureItem
          img={connect}
          title="Connect with customers"
          text="connect description"
          featuresList={["Real time updates", "Email automation"]}
          buttonText="Sign up"
        />
        <FeatureItem
          img={improve}
          title="Improve your sales"
          text="improve description"
          featuresList={["Customer metrics", "Guarantee customer satisfaction"]}
          buttonText="Sign up"
        />
        <FeatureItem
          img={marketing}
          title="Invest in marketing"
          text="marketing description"
          featuresList={["Marketing automation", "Email tracking"]}
          buttonText="Sign up"
        />
      </div>
    </section>
  </ScrollableAnchor>
);

const FeatureItem = props => (
  <div className="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
    <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
      <a
        href="https://www.gatsbyjs.org/"
        className="flex flex-wrap no-underline hover:no-underline"
      >
        <img className="w-full imgFile" src={props.img} />
        <div className="w-full pt-2 text-center font-bold text-2xl text-gray-800 px-6">
          <FormattedMessage id={props.title} />
        </div>
        <p className="text-gray-800 pt-2 text-base text-justify px-6 mb-5">
          <FormattedMessage id={props.text} />
        </p>
      </a>
    </div>
    <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow px-6">
      <div className="features">
        <p className="text-center font-bold text-2xl pb-2"><FormattedMessage id="Features" /></p>
        <ul>
          {props.featuresList.map(feature => (
            <li className="py-1"><FormattedMessage id={feature} /></li>
          ))}
        </ul>
      </div>
      <div className="flex items-center justify-center">
      <OutboundLink href="https://app.closer-office.com/auth/register" target="__blank" className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
          <FormattedMessage id={props.buttonText} />
        </OutboundLink>
      </div>
    </div>
  </div>
);
